import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    backgroundColor:"#22272f",
    borderRadius: 10,
    padding: theme.spacing(3, 8),
    color: "#ffffff",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 0),
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: "#C8C8C8",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(0.5),
    },
  },
  desc: {
    fontSize: 16,
    color: "#C8C8C8",
  },
  
  feature: {
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "solid 1px rgba(255, 255, 255, 0.08)",
      padding: theme.spacing(1, 3, 2, 3),
    },
    [theme.breakpoints.down(340)]: {
      borderBottom: "solid 1px rgba(255, 255, 255, 0.08)",
      padding: theme.spacing(1, 2, 2, 2),
    },
    "&:last-child": {
      border: "none",
    },
  },
}));
