import Lottie from "lottie-react";
import whatsapp from "./whatsapp.json";

const style = {
  position: "relative",
  zIndex: 1,
  width: "100%",


} as React.CSSProperties;

const Whatsapp = () => {
  return (
    <Lottie
      style={style}
      animationData={whatsapp}
      loop={true}
      autoplay={true}
      
    />
  );
};

export default Whatsapp;