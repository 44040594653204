import {
  Typography,
  Container,
  Grid,
  Hidden,
  List,
  ListItem,
  Button
} from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import useStyles from "./style";
import { productDetail } from "../../components/product";
import { getProductDetail } from "../../api/services";
import {useParams} from "react-router-dom";
import './style.css';
import Whatsapp from "./files/whatsapp";
import Features from "./features";

import Slider, { ISlide } from "./slider/index";

interface IProp {
  slides?: ISlide[];
}


const Faqs = [
  {"question": "what is the maximum of production time?",
  "Answer": "It depends to many factors"},
  {"question": "what is the maximum of production time?",
  "Answer": "It depends to many factors"},
  {"question": "what is the maximum of production time?",
  "Answer": "It depends to many factors"},
  {"question": "what is the maximum of production time?",
  "Answer": "It depends to many factors"},
  {"question": "what is the maximum of production time?",
  "Answer": "It depends to many factors"}
]




const Component: FunctionComponent<IProp> = ({ slides}) => {
  const classes = useStyles();
  const [product, setProduct] = React.useState<productDetail>();
  // const [faqs, setFaqs] =  React.useState<setFaqs>();
  const [laoding, setLoading] = React.useState<boolean>(true);
  const [isVisible, setIsVisible] = React.useState<number>(1);
  const [FaqSelected, FaqSelect] = React.useState<number>(-1);

  const { id }: any = useParams();
  React.useEffect(() => {
    fetchProductData();
  }, []);

  const myComponentList = Faqs.map((item, index) => (
    <ListItem button className={classes.FaqList} key={index} onClick={() => FaqSelect(index)}><b>{index + 1} - {item["question"]}</b>
    {
      index == FaqSelected &&
      <ListItem className={classes.FaqAnswer} selected={index == FaqSelected}>{item["Answer"]}</ListItem>
    }
        
    </ListItem>
  ));

  const fetchProductData = () => {
    getProductDetail(id)
      .then((response: any) => {
        console.log(response)
        setLoading(false);
        if (response) {
          setProduct(response);
        }
      })
      .catch((er) => {
        setLoading(false);
      });
  };


  return (
    <>
      {laoding ? (
        <div className={classes.loading}>
          <img src="/images/loading.svg" alt="loading" />
        </div>
      ) : (
        <>
          <Grid className={classes.container}>
            {product && (
              
              <Container className={classes.root}>
                  <div className={classes.title}>
                      <Typography className={classes.title}>
                          {product.title}
                      </Typography>
                  </div>
                  <div className={classes.whatsappAnimate}>
                    <a href="https://wa.me/+989130650905" target="_blank"><Whatsapp /></a>
                  </div>

                <Grid container justify={"center"}>
                  <Grid item md={9} xs={11}>
                    <Grid item md={12} xs={12}>
                        <Grid>
                        <Grid md={12} xs={12}>
                              <Slider slides={product.product} />
                        </Grid>
                        <Grid md={12} xs={12}>

                            <div
                                className={classes.content}
                                dangerouslySetInnerHTML={{ __html: product.description }}
                            >
                            </div>
                        </Grid>
                        </Grid>
                        <br></br>
                        <Grid md={12} xs={12}>
                            <div className={classes.title}>
                              <Typography className={classes.title}>
                                Why Choose Us               
                             </Typography>
                            </div>
                            <div>
                            <Features />
                            </div>
                            <br></br>
                        </Grid>
                        <List className={classes.ListMenu}    
                            component="nav"
                            aria-label="contacts">
                            <ListItem button onClick={() => setIsVisible(1)} selected={isVisible == 1}>
                              Spec
                            </ListItem>
                              <ListItem button onClick={() => setIsVisible(2)} selected={isVisible == 2}>
                                Pricing
                            </ListItem>
                              <ListItem button onClick={() => setIsVisible(3)} selected={isVisible == 3}>
                                More Details
                              </ListItem>
                        </List>
                        {
                          isVisible == 1 &&

                          <Grid md={12} xs={12}>

                          <div
                              className={classes.content}
                              dangerouslySetInnerHTML={{ __html: product.simple_specification }}
                          >
                          </div>
                        </Grid>
                        }
                        { isVisible == 3 &&

                          <Grid md={12} xs={12}>
                          <div
                              className={classes.content}
                              dangerouslySetInnerHTML={{ __html: product.benefits }}
                          >
                          </div>
                          </Grid>
                                                  }

                    </Grid>
                  </Grid>
                  <Grid md={9} xs={12}>
                  <div className={classes.title}>
                              <Typography className={classes.title}>
                                FAQs              
                             </Typography>
                            </div>
                    <List className={classes.FAQsection}>
                      {myComponentList}
                    </List>

                  </Grid>
                </Grid>
              </Container>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Component;
